<template>
  <!-- loading page -->
  <div
    v-if="loading"
    class="d-flex justify-center"
  >
    <v-progress-circular
      :size="50"
      indeterminate
      color="primary"
    >
    </v-progress-circular>
  </div>
  <div v-else>
    <v-row>
      <!-- CountDown To the event time -->
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          class="greeting-card rounded-xl mx-auto"
          flat
          height="100%"
        >
          <div>
            <v-card-title
              style="word-break: break-word"
              class="justify-center font-weight-semibold align-center text-center py-auto"
            >
              <h5>
                {{ $store.state.eventData.names[$i18n.locale] || $store.state.eventData.names[$store.state.eventData.defaultLang] }}
              </h5>
            </v-card-title>
            <v-card-text class="pa-0 ma-0 mb-4">
              <CountDownToEvent :event-date-props="$store.state.eventData.date"></CountDownToEvent>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
      <!-- Statictic card -->
      <v-col
        cols="12"
        md="9"
      >
        <v-card
          flat
          class="rounded-xl"
        >
          <v-card-title class="align-start">
            <h4 class="font-weight-semibold">
              {{ $t('EventDetails') }}
            </h4>
            <router-link :to="{name: 'apps-user-list' }">
              <v-avatar
                class="v-avatar-light-bg primary--text"
                style="position: absolute; top: 10px;"
                :style="$vuetify.rtl? 'left:10px' : 'right:10px'"
                icon
                size="35"
              >
                <v-icon
                  size="25"
                  color="primary"
                  :style="$vuetify.rtl? 'transform: scaleX(-1);':''"
                >
                  {{ icons.mdiAccountArrowRight }}
                </v-icon>
              </v-avatar>
            </router-link>
          </v-card-title>

          <v-card-subtitle
            v-if="guestStatsData"
          >
            <span class="text-base">
              {{ $t('EventDetailsMessage') }}
            </span>
            <span> {{ guestTotalSum }} </span>
          </v-card-subtitle>

          <v-card-text
            v-if="guestStatsData"
            class="mt-n2"
          >
            <v-row
              justify="center"
              align="center"
              no-gutters
            >
              <v-col
                v-for="(data, key) in guestStatsData"
                :key="key"
                cols="auto"
                offset="3"
                class="d-flex mx-auto flex-grow-1"
              >
                <div
                  class="d-flex align-center flex-grow-1 py-1"
                  style="width: 130px; font-size: 13px;"
                >
                  <v-avatar
                    size="40"
                    :color="getStatusOptionStatsField(key, 'color')"
                    :class="`v-avatar-light-bg ${getStatusOptionStatsField(key, 'color')}--text`"
                  >
                    <v-icon
                      size="25"
                      :color="getStatusOptionStatsField(key, 'color')"
                      class="rounded-0"
                    >
                      {{ getStatusOptionStatsField(key, 'icon') }}
                    </v-icon>
                  </v-avatar>
                  <div
                    class="mx-1"
                  >
                    <p class="text-s mb-0">
                      {{ $t(key) }}
                    </p>
                    <h2 class="text-xl font-weight-semibold mb-0">
                      {{ data }}
                    </h2>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- OverView Chart -->
      <v-col
        cols="12"
        sm="12"
        md="4"
      >
        <GeneralChart
          v-if="guestStatsData"
          :data="guestStatsData"
        ></GeneralChart>
      </v-col>

      <!-- Recent react - last response + change decision -->
      <v-col
        cols="12"
        md="8"
      >
        <RecentReact
          v-if="changedDecisionData
            && lastRespondersData"
          :last-responders-data="lastRespondersData"
          :changed-decision-data="changedDecisionData"
        ></RecentReact>
      </v-col>

      <!-- Overview attending by groups chart -->
      <v-col
        cols="12"
        sm="12"
        md="4"
      >
        <GroupsChartArrive
          v-if="groupStatsData"
          :data="groupStatsData"
        ></GroupsChartArrive>
      </v-col>

      <!-- Overview by groups chart -->
      <v-col
        cols="12"
        sm="12"
        md="8"
      >
        <GroupsChart
          v-if="groupStatsData"
          :data="groupStatsData"
        ></GroupsChart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiAccountAlert, mdiAccountArrowRight } from '@mdi/js'
// eslint-disable-next-line import/no-cycle
import CountDownToEvent from '@/components/EventStatistics/CountDownToEvent.vue'
import GeneralChart from '@/components/EventStatistics/GeneralChart.vue'
import GroupsChart from '@/components/EventStatistics/GroupsChart.vue'
import GroupsChartArrive from '@/components/EventStatistics/GroupsChartArrive.vue'
import RecentReact from '@/components/EventStatistics/RecentReact.vue'
// eslint-disable-next-line import/no-cycle
import useGuestStatuses from '@/composables/useGuestStatuses'
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    GeneralChart,
    CountDownToEvent,
    RecentReact,
    GroupsChartArrive,
    GroupsChart,
  },

  setup() {
    const changedDecisionData = ref()
    const groupStatsData = ref()
    const guestTotalSum = ref(0)
    const guestStatsData = ref()
    const lastRespondersData = ref()
    const loading = ref(true)
    const toast = useToast()
    const showToast = () => toast.error(i18n.t('errorManageEvents'))
    const { statusOptions } = useGuestStatuses()
    const fetchStatisticsEvent = () => {
      store
        .dispatch('eventStatisticsDetails', { id: store.state.eventData.id })
        .then(res => {
          changedDecisionData.value = Object.values(res.data.changedDecision || {}).filter(el => el.prevStatus !== 1)
          groupStatsData.value = res.data.groupStats
          guestStatsData.value = res.data.guestStats

          // filtering out 'pending' or 'inProgress'
          const filterKey = store.state.eventData.progressStatus > 0 ? 'pending' : 'inProgress'
          const { [filterKey]: _, ...filteredStats } = guestStatsData.value
          guestStatsData.value = filteredStats

          lastRespondersData.value = res.data.lastResponders

          const sumOfTotalGuests = Object.values(
            Object.keys(guestStatsData.value || {})
              .filter(key => key !== 'changedDecision' && key !== 'viewCount')
              .reduce((cur, key) => Object.assign(cur, { ...sumOfTotalGuests, [key]: guestStatsData.value[key] }), {}),
          ).forEach(el => {
            guestTotalSum.value += Number(el)
          })
        })
        .catch(() => showToast())
        .finally(() => {
          loading.value = false
        })
    }
    fetchStatisticsEvent()

    const statusOptionsStats = [
      ...statusOptions,
      {
        title: 'changedDecision',
        value: 5,
        color: 'orange',
        icon: mdiAccountAlert,
      },
    ]

    const getStatusOptionStatsField = (key, field) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      statusOptionsStats.filter(el => el.title === key).map(el => el[field])[0]

    return {
      statusOptions,
      statusOptionsStats,
      changedDecisionData,
      groupStatsData,
      guestStatsData,
      lastRespondersData,
      guestTotalSum,
      loading,
      getStatusOptionStatsField,

      icons: { mdiAccountArrowRight },
    }
  },
}
</script>
<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
  display: grid;
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
